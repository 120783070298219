import Icon, { IconProps } from '@components/utils/icons/icon';
import NewTabLink from '@components/utils/links/newTabLink';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { CompanyFragment } from '@graphql/generated/graphql';
import {
    OutboundLinkContext,
    OutboundLinkDestinationCategory,
    OutboundLinkShape,
} from '@enums';
import { ReactNode } from 'react';

type Props = {
    links: CompanyFragment['SocialLinks'];
};

const TextToIconMap: Map<string, (color: IconProps['color']) => ReactNode> =
    new Map([
        [
            'Website',
            (color) => (
                <Icon key='Website' name='link' size='large' color={color} />
            ),
        ],
        [
            'Instagram',
            (color) => (
                <Icon
                    key='Instagram'
                    name='instagram'
                    size='large'
                    color={color}
                />
            ),
        ],
        [
            'Facebook',
            (color) => (
                <Icon
                    key='Facebook'
                    name='facebook'
                    size='large'
                    color={color}
                />
            ),
        ],
        [
            'Twitter',
            (color) => (
                <Icon
                    key='TwitterX'
                    name='twitter'
                    size='large'
                    color={color}
                />
            ),
        ],
        [
            'LinkedIn',
            (color) => (
                <Icon
                    key='LinkedIn'
                    name='linkedin'
                    size='large'
                    color={color}
                />
            ),
        ],
        [
            'MailTo',
            (color) => (
                <Icon key='Mail' name='mail' size='large' color={color} />
            ),
        ],
    ]);

const Container = styled.div`
    display: flex;
    justify-content: center;
    padding-inline: ${(props) => props.theme.spacing.xl};
    flex-wrap: wrap;
`;

const LinkBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 100%;
    overflow: hidden;
    padding: ${(props) => props.theme.spacing.xs};
`;

const SocialsSection: React.FC<Props> = ({ links }: Props) => {
    const theme = useTheme();
    return (
        <Container>
            {links!.map((link) => {
                return (
                    <NewTabLink
                        href={link!.Url!}
                        key={link!.LinksTo}
                        destination_name={link!.LinksTo!}
                        destination_category={
                            OutboundLinkDestinationCategory.SocialMedia
                        }
                        shape={OutboundLinkShape.Visual}
                        context={OutboundLinkContext.Footer}
                    >
                        <LinkBox>
                            {TextToIconMap.get(link!.LinksTo!)?.(
                                theme.palette.text.light
                            )}
                        </LinkBox>
                    </NewTabLink>
                );
            })}
        </Container>
    );
};

export default SocialsSection;
