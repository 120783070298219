import Accordion from '@components/utils/Accordion';
import { CompanyInfoFragment } from '@graphql/generated/graphql';
import ProductItemList from './productListItem';
import styled from '@emotion/styled';
import ProductCategory from './productCategory';

type Props = {
    productCollection?: CompanyInfoFragment['ProductCollection'];
};

const AccordionContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing.sm};
`;

const ProductCollection = ({ productCollection }: Props) => {
    if (!productCollection) return null;

    return (
        <>
            {productCollection.map((item, index) => {
                switch (item.__typename) {
                    case 'ComponentUtilsProductCategory': {
                        if (item.IsCollapsible) {
                            return (
                                <Accordion
                                    marginTitle='md'
                                    key={index}
                                    title={item.Title}
                                    index={index}
                                    spacing='xxs'
                                    titleVariant='heading4'
                                    defaultOpen={true}
                                >
                                    <AccordionContent>
                                        {item.Products?.map(
                                            (product, index) => (
                                                <ProductItemList
                                                    key={index}
                                                    product={product.Product!}
                                                />
                                            )
                                        )}
                                    </AccordionContent>
                                </Accordion>
                            );
                        }

                        return (
                            <ProductCategory
                                productCategory={item}
                                key={index}
                            />
                        );
                    }
                    case 'ComponentUtilsSimpleProduct': {
                        return (
                            <ProductItemList
                                key={index}
                                product={item.Product!}
                            />
                        );
                    }
                    default:
                        return null;
                }
            })}
        </>
    );
};

export default ProductCollection;
