import styled from '@emotion/styled';
import { ProductCategoryFragment } from '@graphql/generated/graphql';
import ProductItemList from './productListItem';

type Props = {
    productCategory?: ProductCategoryFragment;
};

const VStack = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing.xxs};
`;

const Title = styled.h3`
    font: ${(props) => props.theme.fonts.heading4};
    margin-inline: ${(props) => props.theme.spacing.md};
`;

const ProductList = styled.div`
    padding-block: ${(props) => props.theme.spacing.sm};
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing.sm};
`;

const ProductCategory = ({ productCategory }: Props) => {
    return (
        <VStack>
            <Title>{productCategory?.Title}</Title>
            <ProductList>
                {productCategory?.Products?.map((product, index) => (
                    <ProductItemList key={index} product={product.Product!} />
                ))}
            </ProductList>
        </VStack>
    );
};

export default ProductCategory;
