import { TRANSITION_DURATION_IN_SECONDS } from '@constants';
import { StyledProps } from '@emotion/react';
import styled from '@emotion/styled';
import {
    Variants,
    motion,
    useAnimationControls,
    AnimatePresence,
} from 'framer-motion';
import { MouseEvent, ReactNode, useState } from 'react';
import Icon from '@components/utils/icons/icon';
import { Theme } from '@emotion/react';

type AccordionProps = {
    index: number;
    title?: ReactNode;
    children?: ReactNode;
    titleVariant?: keyof Theme['fonts'];
    marginTitle?: keyof Theme['spacing'];
    spacing?: keyof Theme['spacing'];
    defaultOpen?: boolean;
    onOpen?: (index: number) => void;
    onClose?: (index: number) => void;
};

type ToggleOpenEvent =
    | MouseEvent<HTMLDetailsElement, globalThis.MouseEvent>
    | MouseEvent<HTMLDivElement, globalThis.MouseEvent>;

type AccordionContainerProps = {
    isFirst: boolean;
    spacing?: keyof Theme['spacing'];
};

const AccordionContainer = styled.details<StyledProps<AccordionContainerProps>>`
    width: 100%;
    padding-bottom: ${(props) => props.theme.spacing[props.spacing || 'md']};
    padding-top: ${(props) =>
        props.isFirst || props.theme.spacing[props.spacing || 'md']};
    cursor: pointer;
`;

const AccordionSummary = styled.summary<{
    marginTitle?: keyof Theme['spacing'];
}>`
    display: block;
    margin-inline: ${(props) =>
        props.marginTitle ? props.theme.spacing[props.marginTitle] : 0};
    ::-webkit-details-marker {
        display: none;
    }
`;

const AccordionContent = styled(motion.div)`
    height: 0;
    overflow-y: hidden;
    padding-block: ${(props) => props.theme.spacing.sm};
    transform: translateY(${(props) => props.theme.spacing.sm});
`;

const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    gap: ${(props) => props.theme.spacing.md};
`;

const Title = styled.h3<{ variant: keyof Theme['fonts'] }>`
    font: ${(props) => props.theme.fonts[props.variant]};
`;

const IconBox = styled(motion.div)`
    height: max-content;
`;

const iconMotion: Variants = {
    open: { rotate: '180deg' },
    closed: { rotate: '0deg' },
};

const contentMotion: Variants = {
    initial: { height: '0' },
    animate: { height: 'auto' },
    exit: { height: '0' },
};

const Accordion = ({
    index,
    title,
    children,
    spacing,
    titleVariant = 'heading5',
    marginTitle,
    defaultOpen = false,
    onOpen,
    onClose,
}: AccordionProps) => {
    const [isOpenDetails, setIsOpenDetails] = useState(defaultOpen);
    const [isOpenContent, setIsOpenContent] = useState(defaultOpen);
    const controls = useAnimationControls();

    const handleOpen = () => {
        setIsOpenDetails(true);
        setIsOpenContent(true);
        controls.start('open');
        onOpen?.(index);
    };

    const toggleOpen = async (e: ToggleOpenEvent) => {
        if (e.target instanceof Element) {
            if (e.target.tagName.toLowerCase() === 'a') {
                return;
            }
            e.preventDefault();
        }

        if (!isOpenDetails) {
            handleOpen();
        } else {
            controls.start('closed');
            setIsOpenContent(false);
            onClose?.(index);
        }
    };

    return (
        <AccordionContainer
            open={isOpenDetails}
            onClick={toggleOpen}
            isFirst={index === 0}
            spacing={spacing}
        >
            <AccordionSummary marginTitle={marginTitle}>
                <Flex>
                    <Title variant={titleVariant}>{title}</Title>
                    <IconBox
                        animate={controls}
                        variants={iconMotion}
                        transition={{
                            duration: TRANSITION_DURATION_IN_SECONDS,
                        }}
                    >
                        <Icon size='medium' name={'keyboard_arrow_down'} />
                    </IconBox>
                </Flex>
            </AccordionSummary>
            <AnimatePresence onExitComplete={() => setIsOpenDetails(false)}>
                {isOpenContent && (
                    <AccordionContent
                        variants={contentMotion}
                        initial='initial'
                        animate='animate'
                        exit='exit'
                        transition={{
                            duration: TRANSITION_DURATION_IN_SECONDS,
                        }}
                    >
                        {children}
                    </AccordionContent>
                )}
            </AnimatePresence>
        </AccordionContainer>
    );
};

export default Accordion;
